import { mapper, productsConfigFiles } from '@products/config';

const prepareProperties = (configStep: IObject | null,
  list: IObject[]): IStepProperties[] => (list.map((property: IObject,
  index:number): IStepProperties => ({
  ...property,
  name: property.name,
  required: property.required,
  format: property.format,
  valueId: configStep ? configStep[index].id : '',
})));

const prepareSteps = (configSteps: IObject[],
  list: IObject[]): IStep[] => (list.map((step: IObject): IStep => {
  const configStep = configSteps && configSteps.length > 0 ? configSteps[step.order - 1] : null;
  const properties = prepareProperties(configStep, step.properties);
  return {
    name: step.name,
    khmerName: step.khmerName,
    order: step.order,
    description: step.description,
    properties,
    view: step.view,
    formContainer: {
      title: '', forms: [], count: 3, type: 'default',
    }, // this item will be set on product model instantiation
    componentType: '', // this item will be set on product model instantiation
  };
}));

const getConfigFile = (productCode: number): IProductConfig => {
  const product = mapper.find((map: IObject) => {
    if (map.productCode === productCode) return map;
    return null;
  });
  if (product) return (productsConfigFiles as IObject)[product.fileName];
  return {} as IProductConfig;
};

const prepareObj = (obj: IObject): IProduct => {
  const productConfig = getConfigFile(obj.id);
  return {
    id: obj.id,
    name: obj.name,
    orgUnit: obj.orgUnit,
    steps: obj.steps ? prepareSteps(productConfig.steps, obj.steps) : [],
    icon: obj.iconName || 'icon-planeProduct',
    isActive: obj.onBoarded,
  };
};

const prepareList = (list: IObject[]): IProduct[] => {
  const preparedList = list.map((item: IObject): IProduct => prepareObj(item));
  const all: IProduct = {
    id: 0,
    name: 'All',
    orgUnit: '',
    steps: [],
    icon: '',
    isActive: false,
  };
  return preparedList.length > 1 ? [all, ...preparedList] : preparedList;
};

const product = { getConfigFile, prepareObj, prepareList };
export default product;
